<template>
  <b-container fluid class="sub_header__container">
    {{ title }}
  </b-container>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.sub_header__container {
  background-color: #084085;
  padding: 8px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
@media (max-width: 992px) {
  .sub_header__container {
    font-size: 13px;
  }
}
</style>
