<template>
  <b-container>
    <b-container class="mt-4">
      <h2>Stromanbieter vergleichen und sparen!</h2>
      <p><b-icon-check></b-icon-check> Sichere dir bis zu 260 € Bonus</p>
      <p><b-icon-check></b-icon-check> Ein garantiert sicherer Wechseln</p>
      <p>
        <b-icon-check></b-icon-check> Verbraucherfreundlicher Tarifvergleich
      </p>
    </b-container>
    <b-button @click="comparison" class="base__button w-100">
      <b-spinner v-if="isLoading" small></b-spinner>
      {{ button }}
    </b-button>
  </b-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isLoading: false,
      button: "Jetzt zum Vergleich!",
    };
  },
  methods: {
    comparison() {
      this.$el.childNodes[0].setAttribute("disabled", true);
      this.isLoading = true;
      this.button = "Weiterleitung zum Vergleichsrechner!";
      setTimeout(() => {
        window.location.href = "https://bit.ly/3AYFzZZ";
      }, 3500);
    },
  },
};
</script>

<style lang="scss" scoped>
.base__button {
  background-color: #0063ce;
  font-weight: 600;
  margin-top: 20px;
}
</style>
