<template>
  <b-container fluid class="p-0">
    <the-header :title="title" :subtitle="subtitle"></the-header>
    <transition name="fade" mode="out-in" appear>
      <router-view />
    </transition>
  </b-container>
</template>

<script>
import TheHeader from "./components/layout/TheHeader.vue";
export default {
  components: {
    TheHeader,
  },
  data() {
    return {
      title: "Stromvergleich",
      subtitle: "Jetzt vergleichen und bis zu 750 € im Jahr sparen!",
    };
  },
};
</script>

<style lang="scss">
.selector-for-some-widget {
  box-sizing: content-box;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
