<template>
  <b-container fluid class="p-0">
    <b-container fluid class="navbar__container p-0">
      <b-container>
        <b-navbar variant="faded">
          <b-navbar-brand>{{ title }}</b-navbar-brand>
        </b-navbar>
      </b-container>
    </b-container>
    <sub-header :title="subtitle"></sub-header>
  </b-container>
</template>

<script>
import SubHeader from "../block/SubHeader.vue";
export default {
  props: {
    title: String,
    subtitle: String,
  },
  components: {
    SubHeader,
  },
};
</script>

<style lang="scss" scoped>
.navbar__container {
  background-color: #063773;
  padding: 10px !important;
}
.navbar-brand {
  color: #fff !important;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
